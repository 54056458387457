<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="container">
      <div class="row">
        <div class="col" v-if="farmas_bool">
          <div class="form-group">
            <label for="provider">Fornecedor</label>
            <input class="form-control" type="text" :value="this.getInvoicesSuppliersId[0].supplier" disabled />
          </div>
          <div class="form-group">
            <label for="invoiceNumber">Número da Fatura</label>
            <input type="text" class="form-control" id="invoiceNumber" v-model="invoiceId" />
          </div>
          <!-- DATA -->
          <!--  <div class="form-group">
            <label for="date">Data</label>
            <input
              type="date"
              class="form-control"
              v-model="date_if"
              disabled
            />
          </div> -->
        </div>
        <div class="col" v-else>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Fornecedor</span>
            </div>
            <v-select
              @input="updateInvoiceType()"
              :options="suppliers"
              v-model="supplier"
              :reduce="(supp) => supp.name"
              label="name"
              disabled
            >
              <template slot="option" slot-scope="option"> {{ option.name }}</template>
              <template slot="selected-option" slot-scope="option"> {{ option.name }}</template>
            </v-select>
            <b-button v-if="supp_url != null" @click="openSite()">🌐</b-button>
            <button
              v-if="tipo_fatura == 'Papel' && supp_contact !== null"
              class="btn btn-primary"
              @click="sendEmailInvoice()"
            >
              Pedir Fatura
            </button>
            <!-- <select
                class="form-select"
                aria-label="Default select example"
                v-model="supplier"
              >
                <option v-for="supplier in suppliers" :key="supplier.id">
                  {{ supplier.name }} ({{ supplier.other_name }})
                </option>
              </select> -->
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">NIF</span>
            </div>
            <input type="text" class="form-control" id="invoiceNumber" v-model="supp_nif" disabled />
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Contacto</span>
            </div>
            <input type="text" class="form-control" id="invoiceNumber" v-model="supp_contact" disabled />
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Nº Fatura</span>
            </div>
            <input type="text" class="form-control" id="invoiceNumber" v-model="invoiceId" disabled />
            <div class="input-group-append">
              <span class="input-group-text"><a v-on:click="file ? openInvoice() : null">📄</a></span>
            </div>
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Tipo Fatura</span>
            </div>
            <input type="text" class="form-control" id="invoiceNumber" v-model="tipo_fatura" disabled />
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Pagamento</span>
            </div>
            <select
              class="form-select form-select"
              id="exampleFormControlSelect1"
              v-model="paymentMethod"
              style="font-size: 100%"
              disabled
            >
              <option value=""></option>
              <option value="PayPal">PayPal</option>
              <option value="Referência MB">Referência MB</option>
              <option value="Cartão Crédito">Cartão Crédito</option>
            </select>
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Pago</span>
            </div>
            <input type="number" v-model="totalPay" class="form-control" disabled />
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="input-group input-group-sm mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Pedido</span>
                </div>
                <input type="date" v-model="data_f" class="form-control" disabled />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input-group input-group-sm mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Recebido</span>
                </div>
                <input type="date" v-model="data_i" class="form-control" disabled />
              </div>
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">IMG Receção</span>
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="img_reception"
                @change="addImgReception()"
              />
              <div class="input-group-append">
                <span class="input-group-text"><a v-on:click="img_reception ? openImage() : null">🖼️</a></span>
              </div>
            </div>
            <div v-if="expectCreditNote" class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Nota de Crédito</span>
              </div>
              <input type="text" class="form-control" id="invoiceNumber" v-model="creditNoteNumber" disabled />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><a :disabled="!fileCreditNote ? true : false" @click="openCreditNote()">📄</a></span
                >
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Valor N.Crédito</span>
              </div>
              <input type="number" class="form-control" v-model="creditNoteValue" disabled />
              <div class="input-group-append" v-if="!creditNoteNumber && !creditNoteValue">
                <span class="input-group-text"><a v-b-modal.creditNoteModal>➕</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="column">
              <div class="box box1">
                <img :src="img" />
              </div>
            </div>
            <div class="column">
              <form>
                <div class="form-group">
                  <label for="sku">SKU</label>
                  <input type="text" class="form-control" id="sku" v-model="skuInfo" disabled />
                </div>
                <div class="form-group">
                  <label for="ean">EAN</label>
                  <input type="text" class="form-control" id="ean" v-model="eanInfo" disabled />
                </div>
                <div class="form-group">
                  <label for="productName">Nome</label>
                  <input type="text" class="form-control" id="productName" disabled v-model="nameInfo" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col" style="text-align: right"><b>Sem IVA</b></div>
        <div class="col-sm-1" style="text-align: center">
          <b-form-checkbox v-model="with_iva" name="check-button" switch size="lg"> </b-form-checkbox>
        </div>
        <div class="col" style="text-align: left"><b>Com IVA</b></div>
      </div>
    </div>
    <div style="text-align: center; font-size: 25px; margin-top: 10px">
      <p>
        <b>Fatura <span v-if="with_iva">Com IVA</span> <span v-else>Sem IVA</span></b>
      </p>
    </div>
    <form v-on:submit.prevent="getProduct()">
      <div id="searchEan" class="form-group row">
        <label for="productEan" id="lblProductEan" class="col-sm col-form-label"><b>Cód. Produto:</b></label>
        <div class="col-sm">
          <input type="text" class="form-control" id="productEan" autofocus v-model="ean" />
        </div>
        <div class="col-sm">
          <button class="btn btn-primary">Adicionar</button>
        </div>
      </div>
    </form>
    <table class="table" id="newOrder">
      <thead>
        <tr>
          <th scope="col">SKU</th>
          <th scope="col" style="width: 35%">Nome</th>
          <th scope="col">Qtd.</th>
          <th scope="col">Qtd. Conf.</th>
          <th scope="col" v-if="!farmas_bool && with_iva">Preço (c/IVA)</th>
          <th scope="col" v-else>Preço (s/IVA)</th>
          <th scope="col" style="width: 7%">IVA %</th>
          <th scope="col">Desc.</th>
          <th scope="col">Desc. %</th>
          <th scope="col" style="width: 3%">Validade</th>
          <th scope="col">Peso (g)</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in newProducts"
          :key="product.id"
          :style="product.sku == skuInfo ? { 'background-color': '#ff9393' } : { 'background-color': 'white' }"
        >
          <td>
            <a id="openArticle" v-b-modal.modal-3 @click="passProductData(product)">{{ product.sku }}</a>
          </td>
          <td>{{ product.name }}</td>
          <td>
            <input type="text" class="form-control" v-model="newProducts[index].qty_received" />
          </td>
          <td>
            {{ newProducts[index].qty_confirmed }}
          </td>
          <td>
            <input class="form-control" type="text" v-model="newProducts[index].price" />
          </td>
          <td>
            <input class="form-control" type="text" v-model="newProducts[index].iva" />
          </td>
          <td>
            <input class="form-control" type="text" v-model="newProducts[index].discount" />
          </td>
          <td>
            <input class="form-control" type="text" v-model="newProducts[index].discount_per" />
          </td>
          <td>
            <date-picker
              v-model="newProducts[index].validity"
              type="month"
              format="YYYY-MM"
              value-type="format"
              :lang="lang"
            ></date-picker>
            <span id="infinity" @click="setDataInfinity(newProducts[index])"> Não tem validade </span>
          </td>
          <td>
            <input class="form-control" type="number" v-model="newProducts[index].weight" />
          </td>
          <td v-if="newProducts[index].discount_per == '' || newProducts[index].discount_per == undefined">
            {{
              (newProducts[index].total_price =
                Number(newProducts[index].price) * Number(newProducts[index].qty_received) -
                newProducts[index].discount)
            }}€
          </td>
          <td v-else>
            {{
              (newProducts[index].total_price =
                Number(newProducts[index].price) *
                Number(newProducts[index].qty_received) *
                (1 - newProducts[index].discount_per / 100)).toFixed(2)
            }}€
          </td>
          <span style="display: none" v-if="with_iva">
            {{
              (newProducts[index].price_no_iva = (
                (Number(newProducts[index].price) -
                  Number(newProducts[index].discount) / Number(newProducts[index].qty_received)) /
                1.23
              ).toFixed(2))
            }}
          </span>
          <span style="display: none" v-else>
            {{
              (newProducts[index].price_no_iva = Number(newProducts[index].price) - Number(newProducts[index].discount))
            }}
          </span>
        </tr>
      </tbody>
    </table>
    <div>
      <h5>
        Desconto
        <input type="number" v-model="discount" max-length="8" />
      </h5>
      <h5>
        Valor Extra
        <input type="number" v-model="extraValue" max-length="8" />
      </h5>
      <h5 v-if="!with_iva">
        Total s/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
        <br />
        Valor IVA:
        <b>
          {{
            (
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) *
                1.23 -
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              )
            ).toFixed(2)
          }}€
        </b>
        <br />
        Total c/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return sum + Number(product.total_price) * (1 + Number(product.iva) / 100);
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
      </h5>
      <h5 v-if="!farmas_bool && with_iva">
        Total s/IVA:
        <b>
          {{
            (
              this.newProducts.reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue)) / 1.23
            ).toFixed(2)
          }}€ </b
        ><br />
        Valor IVA:
        <b>
          {{
            (
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) -
              this.newProducts.reduce(
                (sum, product) => sum + Number(product.total_price),
                Number(-this.discount) + Number(this.extraValue)
              ) /
                1.23
            ).toFixed(2)
          }}€ </b
        ><br />
        Total c/IVA:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return sum + Number(product.total_price);
              }, Number(-this.discount) + Number(this.extraValue))
              .toFixed(2)
          }}€
        </b>
      </h5>

      <div class="buttons">
        <!-- <button
          type="submit"
          class="btn btn-primary"
          @click="updateInvoice('processing')"
        >
          Guardar
        </button>
        <button
          v-if="!farmas_bool"
          type="submit"
          class="btn btn-warning"
          @click="saveWithoutInvoice('invoice')"
        >
          Guardar s/ Fatura
        </button> -->
        <button type="submit" class="btn btn-success" @click="updateInvoice()">Finalizar</button>
      </div>
      <b-modal ref="modal_product" id="modal-3" title="Produto" size="lg" hide-footer>
        <div class="card">
          <b-container>
            <b-row align-v="center"
              ><b-col class="left">
                <!-- IMAGEM DO PRODUTO -->
                <img :src="imgProduct" />
              </b-col>
              <b-col>
                <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
                <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
                <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
                <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
                <p>
                  <span id="subtitle">Notas Arm.:</span>
                  {{ warehouseProduct }}
                </p>
                <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
                <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
              </b-col></b-row
            >
          </b-container>

          <button class="btn btn-info" @click="openProdPage()">Editar Produto</button>
        </div>
      </b-modal>
      <b-modal id="creditNoteModal" ref="creditNoteModal" title="Nota de Crédito">
        <div class="form-group">
          <label for="creditNoteNumber">Nº da Nota de Crédito</label>
          <input type="text" class="form-control" id="creditNoteNumber" v-model="creditNoteNumber" />
        </div>
        <div class="form-group">
          <label for="creditNoteNumber">Valora da Nota de Crédito</label>
          <input type="text" class="form-control" id="creditNoteNumber" v-model="creditNoteValue" />
        </div>
        <div class="form-group">
          <label for="fileInput">Inserir Nota de Crédito</label>
          <input type="file" class="form-control" id="fileInput" @change="handleFileCreditNoteUpload" />
        </div>
        <input type="text" class="form-control" id="textInput" v-model="creditNoteURL" placeholder="Link da Fatura" />
        <div slot="modal-footer">
          <button class="btn btn-primary" @click="addCreditNoteFile()">Adicionar Nota de Crédito</button>
          <b-button @click="closeModal()">Fechar</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      eans: [],
      ean: '',
      products: [],
      productInfo: [],
      skuInfo: '',
      eanInfo: '',
      nameInfo: '',
      img: 'https://www.svgrepo.com/show/97910/image.svg',
      newProducts: [],
      invoiceId: '',
      supplier: '',
      date: '',
      suppliers: [],
      val_date_min: '',
      val_date_max: '',
      date_if: '',
      farmas_bool: false,
      loading: false,
      discount: 0,
      extraValue: 0,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
      paymentMethod: '',
      tipo_fatura: '',
      supp_contact: '',
      supp_nif: '',
      supp_url: '',
      totalPay: 0,
      eanProduct: '',
      skuProduct: '',
      hsProduct: '',
      warehouseProduct: '',
      notesProduct: '',
      weightProduct: '',
      imgProduct: '',
      idProduct: '',
      stockProduct: '',
      teste: '',
      teste1: '',
      valueInvoice: 0,
      justification: '',
      with_iva: true,
      data_f: '',
      data_i: '',
      img_reception: '',
      invoiceUrl: '',
      invoiceFile: '',
      file: '',
      fileUrl: '',
      creditNote: false,
      creditNoteURL: '',
      creditNoteFile: '',
      creditNoteValue: '',
      creditNoteNumber: '',
      fileCreditNote: '',
      expectCreditNote: false,
    };
  },
  methods: {
    async openProdPage() {
      window.open(`https://${window.location.host}/product/${this.idProduct}`, '_blank');
      this.$refs['modal_product'].hide();
    },
    async openSite() {
      window.open(this.supp_url, '_blank');
    },
    /* OBTER A LISTA DE TODOS OS PRODUTOS */
    async getAllProducts() {
      /* this.loading = true; */
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
      } catch (err) {
        alert(err);
      } /* finally {
        this.loading = false;
      } */
    },
    /* RECEBER DADOS DA ENCOMENDA JÁ INSERIDA */
    async getDataInformation() {
      /* ID */
      let date = new Date();
      this.val_date_min = `${date.getFullYear()}-${date.getMonth() + 1}`;
      this.val_date_max = `${date.getFullYear() + 5}-${date.getMonth() + 1}`;

      let id = this.$route.params.id;
      await this.$store.dispatch('getInvoicesSuppliersByID', {
        id: id,
      });
      console.log(this.getInvoicesSuppliersId);
      if (
        this.getInvoicesSuppliersId.supplier == 'Farmacia Alvim' ||
        this.getInvoicesSuppliersId.supplier == 'Farmacia Gomes' ||
        this.getInvoicesSuppliersId.supplier == 'Farmacia Perelhal' ||
        this.getInvoicesSuppliersId.supplier == 'Farmacia Gomes'
      ) {
        this.farmas_bool = true;
      }
      let date_iff = this.getInvoicesSuppliersId.date_update;
      let newDate1 = new Date(date_iff.split('T'));
      let year1 = newDate1.getFullYear();
      let month1 = ('0' + (newDate1.getMonth() + 1)).slice(-2);
      let day1 = ('0' + newDate1.getDate()).slice(-2);
      this.date_if = `${year1}-${month1}-${day1}`;

      this.supplier = this.getInvoicesSuppliersId.supplier;
      console.log(this.suppliers);
      const help = this.suppliers.find((item) => item.id == this.getInvoicesSuppliersId.id_supp);
      console.log('aquii', help);
      this.supp_url = help.url;
      this.tipo_fatura = help.invoice;
      this.supp_contact = help.contact;
      this.supp_nif = help.nif;
      /* DATA DA FATURA */
      let date_invoice = this.getInvoicesSuppliersId.date_invoice;
      /* CASO JÁ TENHA SIDO INSERIDO DATA */
      if (date_invoice == null) {
        this.date = date_invoice;
      } else {
        /* TEMOS QUE CRIAR UMA NOVA DATA COM A INFORMAÇÂO RECEBIDA POIS A DATA VEM COM O DIA ANTERIOR
        FORMATO DA DATA YYYY-MM-DDTHH:MM:SSZ TEMOS QUE EXPORTAR SÓ O YYYY-MM-DD */
        let newDate = new Date(date_invoice.split('T'));
        let year = newDate.getFullYear();
        let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
        let day = ('0' + newDate.getDate()).slice(-2);
        this.date = `${year}-${month}-${day}`;
      }
      console.log(this.getInvoicesSuppliersId);
      this.invoiceId = this.getInvoicesSuppliersId.bill_id || '';
      this.paymentMethod = this.getInvoicesSuppliersId.payment_method;
      this.totalPay = this.getInvoicesSuppliersId.bill_value;
      this.newProducts = this.getInvoicesSuppliersId.transactions;
      this.discount = this.getInvoicesSuppliersId.discount;
      this.extraValue = this.getInvoicesSuppliersId.extra_value;
      this.with_iva = this.getInvoicesSuppliersId.with_iva;
      this.img_reception = this.getInvoicesSuppliersId.img;
      this.file = this.getInvoicesSuppliersId.file;
      this.creditNoteValue = this.getInvoicesSuppliersId.credit_note_value;
      this.creditNoteNumber = this.getInvoicesSuppliersId.credit_note_number;
      console.log('CREDIT NOTE', this.getInvoicesSuppliersId.credit_note_value);
      this.fileCreditNote = this.getInvoicesSuppliersId.credit_note_file;
      this.expectCreditNote = this.getInvoicesSuppliersId.credit_note;
      console.log('DATA', this.getInvoicesSuppliersId.date_received);
      this.creditNote = this.getInvoicesSuppliersId.credit_note;
      if (this.getInvoicesSuppliersId.date_received != null)
        this.data_i = this.splitDate(this.getInvoicesSuppliersId.date_received);
      this.data_f = this.splitDate(this.getInvoicesSuppliersId.date_request);
    },
    async setDataInfinity(product) {
      console.log('Infinito');
      console.log(product.validity);
      product.validity = '2050-01';
      console.log(product);
    },
    async updateInvoiceType() {
      const help = this.suppliers.find((item) => item.name == this.supplier);
      this.supp_url = help.url;
      this.tipo_fatura = help.invoice;
      this.supp_contact = help.contact;
      this.supp_nif = help.nif;
    },
    async getEAN() {
      try {
        await this.$store.dispatch('fetchProductsEAN');
        this.eans = this.getProductsEAN;
        console.log(this.eans);
      } catch (err) {
        alert(err);
      }
    },
    async getProduct() {
      console.log(this.products);
      let ean = this.products.filter((product) => product.ean.includes(this.ean));
      console.log(ean);

      /* console.log(sku) */
      /* CASO O VALOR INSERIDO NÃO SEJA DETECTADO VERIFICAMOS SE FOI UM SKU INTRODUZIDO */
      if (ean.length == 0) {
        ean = this.products.filter((product) => product.sku.includes(this.ean));
      }
      console.log(ean);

      /* CASO ENCONTRE PRODUTO ATRAVÈS DO SKU OU EAN VAMOS ADICIONAR O PRODUTO À LISTA  */
      if (ean.length != 0) {
        /* OBTER DADOS DO PRODUTO INSERIDO */
        // this.productInfo = this.products.filter((product) => product.sku.includes(ean[0].sku[0]));
        this.productInfo = ean;
        console.log(this.productInfo);
        /* INSERIR DADOS PARA ILUSTRAR O ÚLTIMO PRODUTO INSERIDO */
        this.nameInfo = this.productInfo[0].name;
        this.eanInfo = this.productInfo[0].ean[0];
        this.skuInfo = this.productInfo[0].sku[0];
        this.img = this.productInfo[0].img;
        let validity = '';
        let weight = '';
        let index = this.newProducts.findIndex((prod) => this.productInfo[0].sku.includes(prod.sku));
        console.log(index);
        if (index == -1) {
          // VERIFICAR SE O PRODUTO JÁ INSERIDO TEM NOTA DE ARMAZÉM
          if (
            this.productInfo[0].note_warehouse != null &&
            this.productInfo[0].note_warehouse != 'null' &&
            this.productInfo[0].note_warehouse != '' &&
            this.productInfo[0].note_warehouse != undefined
          ) {
            await this.$swal({
              title:
                'O produto com SKU:' +
                this.productInfo[0].sku[0] +
                ' tem a seguinte nota: \n' +
                this.productInfo[0].note_warehouse,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK!',
            });
          }
          if (this.productInfo[0].validity != null) {
            validity = this.productInfo[0].validity.substring(0, 7);
          }
          if (this.productInfo[0].weight != null) {
            weight = this.productInfo[0].weight;
          }
          console.log('HERE', this.productInfo);
          let objProduc = {
            id_prod: this.productInfo[0].id_backoffice,
            ean: this.productInfo[0].ean[0],
            sku: this.productInfo[0].sku[0],
            name: this.productInfo[0].name,
            qty_received: 1,
            price: 0.0,
            iva: this.productInfo[0].tax_iva,
            price_no_iva: 0.0,
            discount: 0.0,
            discount_per: 0.0,
            validity: validity,
            total_price: 0.0,
            total_price_no_iva: 0.0,
            weight: weight,
          };
          this.newProducts.push(objProduc);
          this.newProducts.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          // VERIFICAR SE O PRODUTO JÁ INSERIDO TEM NOTA DE ARMAZÉM
          if (
            this.productInfo[0].note_warehouse != null &&
            this.productInfo[0].note_warehouse != 'null' &&
            this.productInfo[0].note_warehouse != '' &&
            this.productInfo[0].note_warehouse != undefined
          ) {
            await this.$swal({
              title:
                'O produto com SKU:' +
                this.productInfo[0].sku[0] +
                ' tem a seguinte nota: \n' +
                this.productInfo[0].note_warehouse,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK!',
            });
          }
          this.newProducts[index].qty_received++;
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: 'Não encontramos o Produto :(',
          showConfirmButton: false,
        });
      }
      this.ean = '';
    },
    async removeProduct(id) {
      const index = this.newProducts.findIndex((obj) => obj.id == id);
      console.log(index);
      this.newProducts.splice(index, 1);
    },
    async updateInvoice() {
      if (
        this.supplier != 'FARMACIA MARTINS' &&
        this.supplier != 'FARMACIA ALVIM' &&
        this.supplier != 'FARMACIA PERELHAL' &&
        this.supplier != 'FARMACIA GOMES' &&
        (this.img_reception == '' || this.img_reception == null || this.img_reception == undefined)
      ) {
        this.$swal({
          icon: 'error',
          title: 'Imagem de receção não inserida',
        });
        return;
      }
      let advance = true;
      let status;
      let sem_data = false;
      let sem_peso = false;
      let sem_iva = false;
      this.valueInvoice = this.totalPay;
      console.log(this.newProducts);
      this.total = this.newProducts.reduce((sum, product) => {
        return sum + Number(product.total_price);
      }, Number(-this.discount) + Number(this.extraValue));
      if (this.with_iva) {
        this.totalWithoutIVA = (
          this.newProducts.reduce((sum, product) => {
            return sum + Number(product.total_price);
          }, Number(-this.discount) + Number(this.extraValue)) / 1.23
        ).toFixed(2);
      } else {
        this.totalWithoutIVA = this.newProducts.reduce((sum, product) => {
          return sum + Number(product.total_price);
        }, Number(-this.discount) + Number(this.extraValue));
        this.total = this.newProducts
          .reduce((sum, product) => {
            return sum + Number(product.total_price) * (1 + Number(product.iva) / 100);
          }, Number(-this.discount) + Number(this.extraValue))
          .toFixed(2);
      }
      let totalConfirmed = this.newProducts.reduce((sum, product) => {
        return sum + Number(product.qty_received);
      }, 0);
      console.log(status);
      console.log('TOTAL INSERIDO', totalConfirmed);

      const date = new Date();
      date.setMonth(date.getMonth() + 6);
      const val_6 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      date.setMonth(date.getMonth() + 12);
      const val_9 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      this.val_6_arr = [];
      this.val_9_arr = [];

      for (const prod of this.newProducts) {
        console.log(prod);
        if (prod.validity == null || prod.validity == undefined || prod.validity == '') sem_data = true;
        if (prod.weight == null || prod.weight == undefined || prod.weight == '') sem_peso = true;
        if (prod.iva == null || prod.iva == undefined || prod.iva == '') sem_iva = true;
        const val_date = new Date(`${prod.validity}-01`);
        console.log(val_6, val_9, val_date);

        if (val_6 > val_date) {
          console.log('Validade inferior a 6 meses');
          console.log(val_6, val_date);
          this.val_6_arr.push(prod);
        } else if (val_6 < val_date && val_9 > val_date) {
          console.log('Validade inferiror a 12 meses');
          console.log(val_9, val_date);
          this.val_9_arr.push(prod);
        }
      }

      if (totalConfirmed == 0) {
        this.$swal({
          icon: 'error',
          title: 'Sem produtos para recepção',
        });
      } else if (sem_data == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem validade',
        });
      } else if (sem_peso == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem peso',
        });
      } else if (sem_iva == true) {
        this.$swal({
          icon: 'error',
          title: 'Produto sem iva',
        });
      } else {
        if (this.invoiceId == '') {
          this.valueInvoice = '';
          if (this.val_6_arr.length != 0 || this.val_9_arr.length != 0) {
            let text = `<p style='color:red;'>Menos de 6 meses</p>`;
            for (const prod of this.val_6_arr) {
              text += `<p>${prod.sku} <> ${prod.validity}</p>`;
            }
            text += `<p style='color:blue;'>Menos de 12 meses</p>`;
            for (const prod of this.val_9_arr) {
              text += `<p>${prod.sku} <> ${prod.validity}</p>`;
            }
            await this.$swal({
              title: 'Existem produtos com validades baixas!',
              icon: 'error',
              html: text,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok!',
            }).then(async (result) => {
              console.log(result);
            });
          }
          await this.$swal({
            title: 'Já tem a fatura impressa na sua mão?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, tenho!',
            cancelButtonText: 'Não, não tenho!',
          }).then(async (result) => {
            if (result.isConfirmed) {
              // INSERIR NUMERO E VALOR DA FATURA
              await this.$swal({
                title: 'Dados da Fatura',
                html: `<input type="text" id="numberInvoice" class="form-control" v-model="teste" placeholder="Nº da Fatura">
                <input type="text" id="invoiceTotal" class="form-control" v-model="teste1" placeholder="Valor Faturado">
                <label>Inserir Fatura</label>
                <input type="file" id="invoiceFile" class="form-control">
                <input type="text" id="invoiceUrl" class="form-control" v-model="teste1" placeholder="URL Fatura">`,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                focusConfirm: false,
                preConfirm: () => {
                  const nInvoice = this.$swal.getPopup().querySelector('#numberInvoice').value;
                  const invoiceTotal = this.$swal.getPopup().querySelector('#invoiceTotal').value;
                  const invoiceFile = this.$swal.getPopup().querySelector('#invoiceFile').files[0];
                  const invoiceUrl = this.$swal.getPopup().querySelector('#invoiceUrl').value;
                  console.log(nInvoice, invoiceTotal, invoiceFile, invoiceUrl);
                  if (!nInvoice || !invoiceTotal || (!invoiceFile && !invoiceUrl)) {
                    this.$swal.showValidationMessage(`Por favor preencha os campos corretamente`);
                  }

                  return {
                    nInvoice: nInvoice,
                    invoiceTotal: invoiceTotal,
                    invoiceFile: invoiceFile,
                    invoiceUrl: invoiceUrl,
                  };
                },
              }).then(async (result2) => {
                if (result2.isConfirmed) {
                  this.invoiceId = result2.value.nInvoice;
                  this.valueInvoice = result2.value.invoiceTotal;
                  this.file = result2.value.invoiceFile;
                  this.fileUrl = result2.value.invoiceUrl;
                  if (!this.file) {
                    await this.addFileReception(this.fileUrl);
                  } else {
                    await this.addFileReception(this.file);
                  }
                  if (result2.value.invoiceTotal < Number(this.total) - 1) {
                    await this.$swal({
                      title: 'Valor recepcionado maior que o faturado!',
                      text: 'Não é possivel validar a receção! Faça as correções necessárias',
                    });
                    advance = false;
                    this.invoiceId = '';
                    this.valueInvoice = '';
                    this.file = '';
                    this.fileUrl = '';
                  } else if (result2.value.invoiceTotal > Number(this.total) + 1) {
                    // VALIDAR QUE O VALOR ESTÁ ERRADO
                    await this.$swal({
                      title: 'Valor recepcionado diferente do recebido!',
                      text: 'Quer validar mesmos assim, ou corrigir?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Validar!',
                      cancelButtonText: 'Corrigir',
                    }).then(async (result3) => {
                      if (result3.isConfirmed) {
                        // Justificar Motivo da Diferença
                        await this.$swal({
                          title: 'Justificação Motivo da Diferença',
                          html: `<input type="text" id="justification" class="form-control" placeholder="Justificação">`,
                          confirmButtonText: 'Confirmar',
                          cancelButtonText: 'Cancelar',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          focusConfirm: false,
                          preConfirm: () => {
                            const justification = this.$swal.getPopup().querySelector('#justification').value;
                            if (!justification) {
                              this.$swal.showValidationMessage(`Por favor preencha os campos corretamente`);
                            }
                            return {
                              justification: justification,
                            };
                          },
                        }).then(async (result4) => {
                          this.justification = result4.value.justification;
                          if (result4.isConfirmed) {
                            this.creditNote = true;
                            advance = true;
                          } else {
                            advance = false;
                          }
                        });
                      } else {
                        advance = false;
                        this.invoiceId = '';
                        this.valueInvoice = '';
                        this.file = '';
                        this.fileUrl = '';
                      }
                    });
                    console.log(result2.value.invoiceTotal);
                  }
                } else {
                  advance = false;
                }
              });
              // advance = true;
            } else {
              advance = true;
            }
          });
        } else {
          await this.$swal({
            title: 'Quer guardar receção?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Cancelar',
          }).then(async (result2) => {
            if (result2.isConfirmed) {
              advance = true;
            } else {
              advance = false;
            }
          });
        }
        if (advance) {
          try {
            await this.$store.dispatch('updateInvoiceSuppliers', {
              invoiceId: this.invoiceId,
              products: this.newProducts,
              date: this.date,
              supplier: this.getInvoicesSuppliersId.id_supp,
              status: 'completed',
              total: this.total,
              id: this.$route.params.id,
              totalWithoutIVA: this.totalWithoutIVA,
              discount: this.discount,
              extraValue: this.extraValue,
              valueInvoice: Number(this.valueInvoice),
              justification: this.justification,
              withIva: this.with_iva,
              creditNote: this.creditNote,
            });
            this.$swal(this.getMessage, '', 'success');
            window.close();
          } catch (err) {
            this.error = true;
            alert(err);
          }
        }
      }
    },
    async addImgReception() {
      try {
        await this.$store.dispatch('addImageProductsReceived', {
          id: this.$route.params.id,
          image: this.img_reception,
        });
        this.$swal('Imagem adicionada com sucesso', '', 'success');
      } catch (err) {
        alert(err);
      }
    },

    async addFileReception(file) {
      try {
        console.log(this.file);
        await this.$store.dispatch('addFileReceptions', {
          id: this.$route.params.id,
          file: file,
        });
        this.$swal('Ficheiro adicionado com sucesso', '', 'success');
      } catch (err) {
        alert(err);
      }
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch('getAllSuppliers');
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name} (${element.other_name})`,
            invoice: element.invoice_type,
            contact: element.email,
            nif: element.nif,
            url: element.url,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async passProductData(data) {
      console.log(data);
      const filterProduct = this.products.filter((product) => product.sku.includes(data.sku));
      console.log(filterProduct);
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(this.eanProduct);
      console.log(this.skuProduct);
      console.log(filterProduct, data);
    },
    async sendEmailInvoice() {
      console.log(this.tipo_fatura, this.supp_contact);
      console.log(this.newProducts);
      /* console.log(this.getInvoicesSuppliersId[0].supplier) */
      try {
        await this.$store.dispatch('requestForInvoiceToSupplier', {
          id: this.$route.params.id,
          email: this.supp_contact,
          products: this.newProducts,
        });
        this.$swal('Email enviado com sucesso', '', 'success');
      } catch (err) {
        alert(err);
      }
    },
    openImage() {
      window.open(this.img_reception, '_blank');
    },
    openInvoice() {
      try {
        new URL(this.file);
        window.open(this.file, '_blank');
      } catch (err) {
        // window.open("data:application/pdf;base64,"+this.file, '_blank');
        let binary = atob(this.file);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blob = new Blob([new Uint8Array(array)], {
          type: 'application/pdf',
        });
        let url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    },
    async openCreditNote() {
      console.log(this.fileCreditNote);
      try {
        new URL(this.fileCreditNote);
        window.open(this.fileCreditNote, '_blank');
      } catch (err) {
        let binary = atob(this.fileCreditNote);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blob = new Blob([new Uint8Array(array)], {
          type: 'application/pdf',
        });
        let url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    },
    async addCreditNoteFile() {
      console.log(this.creditNoteURL, this.creditNoteFile);
      if (!this.creditNoteURL && !this.creditNoteFile) {
        this.$swal({
          icon: 'error',
          title: 'Erro',
          text: 'Tem de inserir um ficheiro da nota de crédito',
        });
      }
      try {
        let file = this.creditNoteURL;
        if (this.creditNoteFile) {
          file = this.creditNoteFile;
        }
        await this.$store.dispatch('addCreditNoteFile', {
          id: this.$route.params.id,
          file: file,
          creditNoteNumber: this.creditNoteNumber,
          creditNoteValue: this.creditNoteValue,
          supplier: this.getInvoicesSuppliersId.id_supp,
        });
        this.$swal('Ficheiro adicionado com sucesso', '', 'success');
        await this.getDataInformation();
        this.$refs.creditNoteModal.hide();
      } catch (err) {
        alert(err);
      }
    },
    closeModal() {
      console.log('AQUI');
      this.$refs.creditNoteModal.hide();
      this.creditNoteFile = '';
      this.creditNoteURL = '';
      this.creditNoteNumber = '';
      this.creditNoteValue = '';
    },
    handleFileCreditNoteUpload(event) {
      console.log(event.target.files[0]);
      this.creditNoteFile = event.target.files[0];
    },
  },
  async created() {
    this.loading = true;
    // await this.getEAN();
    await this.getAllProducts();
    await this.getAllSuppliers();
    await this.getDataInformation();
    this.loading = false;
  },
  computed: {
    // ...mapGetters(['getProductsEAN']),
    ...mapGetters(['getProducts']),
    ...mapGetters(['getInvoicesSuppliersId']),
    ...mapGetters(['getMessage']),
    ...mapGetters(['getSuppliers']),
  },
};
</script>

<style scoped>
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.column {
  float: left;
  width: 50%;
  padding: 5px;
}
#searchEan {
  margin-top: 50px;
}
#lblProductEan {
  text-align: right;
}
/* table {
  table-layout: fixed;
} */
h5 {
  text-align: right;
  margin-right: 7%;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 5px;
}

#infinity:hover {
  cursor: pointer;
  color: aqua;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
a:hover {
  cursor: pointer;
}
</style>
