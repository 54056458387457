<template>
  <div>
    <h1>ADICIONAR PRODUTOS</h1>
    <AddStockUpdate></AddStockUpdate>
  </div>
</template>
<script>
import AddStockUpdate from '@/components/AddStockUpdate.vue';
export default {
  components: {
    AddStockUpdate,
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  padding: 10px;
}
</style>
